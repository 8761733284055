
.p-component {
    font-size: 14px !important;
  }

  
.textbox_type {
  margin: 5px;
  width: 100px;
  text-align: center;
  line-height: 2;
  margin-right: 0;
}

ol {
  display: ruby-text;
  margin: 0;
  padding: 0;
  width: 100%;
  
}

ol > li{
  font-weight: 800;
  width: 0;
  margin: 0;
  padding: 0;
}

:host ::ng-deep .p-button-success {
  color: #ffffff;
  background: #689f38;
  border: 1px solid #689f38;
  padding: 0.5rem 1rem;
}

:host ::ng-deep .p-cell-editing {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
:host ::ng-deep .pi {
  border: none;
  background: none;
}
:host ::ng-deep.p-rowgroup-header {
  color: coral;
  line-height: 1px;
}

:host ::ng-deep .p-button-success {
  color: #ffffff;
  background: #689f38;
  border: 1px solid #689f38;
  padding: 0.5rem 1rem;
  margin-right: 5px;
}
:host ::ng-deep .p-button {
  border-radius: 2rem;
  background-color: transparent;
  color: #3b82f6;
  border-color: transparent;
  border: 1px solid #689f38;
  padding: 0.5rem 1rem;
  margin-right: 5px;
}
:host ::ng-deep .p-button:hover {
  border-color: transparent;
}

:host ::ng-deep .p-overlay-content {
  position: fixed;
}

:host ::ng-deep .col{
  display: inline;
  position: relative;
  margin-left: 5px;
}

:host ::ng-deep td {
  border: none;
}

.p-radiobutton {
  display: inline-flex;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: bottom;
  position: relative
}

.p-radiobutton-box {
  display: flex;
  justify-content: center;
  align-items: center
}

.p-radiobutton-icon {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0) scale(.1);
  border-radius: 50%;
  visibility: hidden
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  transform: translateZ(0) scale(1);
  visibility: visible
}

p-radiobutton {
  display: inline-flex;
  vertical-align: bottom;
  align-items: center
}

.p-radiobutton-label {
  line-height: 1
}